import React from "react";
import { useSelector } from "react-redux";
import OfferItem from "./Item";
import "./offers.scss";
import { useParams } from "react-router-dom";

const Offers = (props) => {
  const params = useParams();
  const siteConfig = props.page
  let isLoading = !params.id ? false : true,
    queryId = params ? params.id : false,
    deepLinkOffer = params.id ? findOffer(params.id) : "";
  let available = useSelector((state) => state.offer.collections.available);
  let featured = useSelector((state) => state.offer.collections.featured);
  let links = siteConfig.colorLinks;

  function findOffer (id) {
    let deepLinkOffer = available.find(
      (cpn) => cpn.mdid === parseInt(id)
    );
    isLoading = false;
    return deepLinkOffer;
  }

  function RenderDeepLink (props) {
    const { cpn } = props;
    return (
      <div>
        <div className="SingleOffer">
          <OfferItem coupon={cpn} key={cpn.mdid} page={siteConfig}/>
        </div>
      </div>
    );
  }

  function RenderOfferList (props) {
    return (
      <div className="offerContent">
        <h2 className="sectionTitle">IN STORE DIGITAL COUPONS</h2>
        <div className="OfferList">
          {props.available.map((cpn) => {
            return <OfferItem coupon={cpn} key={cpn.mdid} page={props.page} />;
          })}
        </div>
      </div>
    );
  }

  function RenderFeaturedList(props) {
    return (
      <div className="FeaturedOfferList">
        {props.featured.map((cpn) => {
          return <OfferItem coupon={cpn} key={cpn.mdid} isFeatured={true} page={props.page} />;
        })}
      </div>
    );
  }

  function trackViewMoreEvent (){
    window.gtag('event', 'View More Coupons', { 
      retailer_id: "Publix",
      version: localStorage.getItem("AB_TEST_VARIABLE") || 'N/A'
    });
  }

  if (isLoading) {
    return "";
  }

  //displays offer-section of page
  if (queryId && deepLinkOffer) {
    return <RenderDeepLink cpn={deepLinkOffer} page={siteConfig} />;
  } else if (!queryId && (available.length > 0 || featured.length > 0)) {
    return (    
      <div className="offer-section" style={{ color: links, paddingBottom: "1%" }}>
        {featured.length > 0 && <RenderFeaturedList featured={featured} page={siteConfig} />}
        <RenderOfferList available={available} page={siteConfig} />
        <br></br>
        {/* <a 
          className="viewMore" 
          href="https://www.publix.com/savings/digital-coupons" 
          onClick={trackViewMoreEvent} style={{ color: links}}
        >View more Publix digital coupons...</a> */}
      </div>
    );
  } else {
    return (
      <div className="offer-section">
        <p>There are no available offers</p>
      </div>
    );
  }
};

export default Offers;

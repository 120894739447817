import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Button, Tabs, Tab, makeStyles } from "@material-ui/core";
import { useLocation } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { formStyles } from "../styles/cpg/form_styles";
import { MobileNavigation } from "./mobileNavigation";
import { AccountCircleOutlined } from "@material-ui/icons";
import accountActions from "../redux/actions/accountActions";
import { ReactComponent as HomeIcon } from "../assets/images/svg/home.svg";
import { ReactComponent as EarnPoontsIcon } from "../assets/images/svg/earn_points.svg";
import { ReactComponent as RedeemIcon } from "../assets/images/svg/redeem.svg";

function HeaderNavigation(props) {
  const headerConfig = props.page.components.find(
    (component) => component.block_type === "HeaderNavigationBlock"
  );

  const isHaveSignUpBlock = props.page.components.find(
    (component) => component.block_type === "SignupBlock"
  );

  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();
  const { pathname } = location;
  const currentPath = pathname.split("/")[2];
  const [currentTab, setCurrentTab] = useState(currentPath);
  const [userInfo, setUserInfo] = useState();
  const user = useSelector((state) => state.user);

  const navigationTabs = user.isLogin
    ? [
        {
          path: "/home",
          slug: "home",
          label: headerConfig.home_tab_name,
          icon: HomeIcon,
        },
        {
          path: "/earn_points",
          slug: "earn_points",
          label: headerConfig.earn_points_tab_name,
          icon: EarnPoontsIcon,
        },
        {
          path: "/redeem",
          slug: "redeem",
          label: headerConfig.redeem_tab_name,
          icon: RedeemIcon,
        },
      ]
    : [
        {
          path: "/home",
          slug: "home",
          label: "Home",
          icon: HomeIcon,
        },
      ];

  const handleChange = (event, newValue) => {
    setCurrentTab(newValue);
    navigate(`/${props.page.brandsite_name}/${newValue}`);
  };

  useEffect(() => {
    const cPath = pathname.split("/")[2];
    setCurrentTab(cPath);
  }, [pathname]);

  useEffect(() => {
    if (!user.points) {
      dispatch(accountActions.updateUserLytPoints(props.page.retailer));
    }

    setUserInfo(JSON.parse(localStorage.getItem("userData")));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const useStyles = makeStyles((theme) => ({
    navigationCtn: {
      display: "flex",
      justifyContent: "space-between",
      padding: "14px 10%",
      background: props.page.color_background,
      alignItems: "center",

      [theme.breakpoints.down("md")]: {
        padding: "10px",
      },
    },
    logo: {
      width: headerConfig.logo_width,
      maxHeight: "74px",

      [theme.breakpoints.down("xs")]: {
        width: "180px",
      },
    },
    rightSideActions: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      width: "70%",

      "& .MuiButton-root": {
        fontFamily: "InterBold",
        fontSize: "16px",
        width: "120px",
        backgroundColor: props.page.color_links,
        margin: "0",
      },

      "& .MuiTabs-indicator": {
        background: props.page.color_text,
      },

      // Hide header nav links in < 960px
      [theme.breakpoints.down("sm")]: {
        "& .MuiTabs-root": {
          display: "none",
        },
      },
    },
    navLink: {
      fontFamily: "InterBold",
      textTransform: "capitalize",
      color: props.page.color_text,
      fontWeight: "700",
      fontSize: "16px",
      width: "130px",
      cursor: "pointer",
      opacity: 1,

      "&:hover": {
        backgroundColor: props.page.color_background,
      },

      "&:focus": {
        outline: "0",
      },
    },
    accountInfo: {
      alignItems: "center",
      cursor: "pointer",
      color: props.page.color_text,
      display: "flex",

      "& .accInfo": {
        marginLeft: "10px",
      },
    },
    mobNavHeader: {
      bottom: "0",
      top: "auto",
      display: "none",

      // Hide header nav links in < 960px
      [theme.breakpoints.down("sm")]: {
        display: "initial",
      },
    },
  }));
  const classes = useStyles();
  const globalClasses = formStyles(props);

  return (
    <>
      <div className={classes.navigationCtn}>
        <div>
          <img
            className={classes.logo}
            src={
              headerConfig.header_logo
                ? headerConfig.header_logo
                : props.page.background_image
            }
            alt="logo"
          />
        </div>

        <div className={classes.rightSideActions}>
          <div>
            <Tabs value={currentTab} onChange={handleChange}>
              {navigationTabs.map((tab, idx) => (
                <Tab
                  value={tab.slug}
                  label={tab.label}
                  wrapped
                  className={classes.navLink}
                  id={`tab-${idx}`}
                />
              ))}
            </Tabs>
          </div>

          <div>
            {isHaveSignUpBlock && !user.isLogin && (
              <Button
                variant="contained"
                onClick={() => navigate(`/${props.page.brandsite_name}/signup`)}
                className={globalClasses.submitBtn}
              >
                <div data-testid="signBtn" className="signBtn">
                  {headerConfig.signup_btn_text}
                </div>
              </Button>
            )}

            {user.isLogin && (
              <div
                variant="contained"
                onClick={() =>
                  navigate(`/${props.page.brandsite_name}/account`)
                }
                className={classes.accountInfo}
              >
                <div style={{ display: "flex" }}>
                  <AccountCircleOutlined
                    className={classes.icon}
                    fontSize="large"
                    aria-label="Go to Account page"
                  />
                </div>
                <div style={{ fontFamily: "InterBold" }}>
                  <div className="accInfo">
                    Hi, {userInfo ? userInfo?.firstName : userInfo?.lastName}
                  </div>
                  <div className="accInfo">{user.points}</div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>

      {user.isLogin && (
        <div className={classes.mobNavHeader}>
          <MobileNavigation props={props} navigationTabs={navigationTabs} />
        </div>
      )}
    </>
  );
}

export default HeaderNavigation;

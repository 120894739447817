import { useDispatch, useSelector } from 'react-redux';
import { SHOW_LOGIN, CLIP_OFFER, CLIP_ERROR } from '../redux/reducers/offer';
import './offerCarouselStyles.scss'

const selectOfferByMdid = (state, mdid) => {
  const offersCollections = state.offer.collections;
  const allOffers = [...offersCollections.available, ...offersCollections.featured];

  // Optionally, if you have more collections, you might need to add them to allOffers too
  // allOffers.push(...offersCollections.otherCollection);

  const offer = allOffers.find((offer) => offer.mdid === mdid);
  return offer;
};

export function OfferCard(props) {
  const dispatch = useDispatch();
  const username = useSelector((state) => state.user.username);
  const stateOffer = useSelector((state) => selectOfferByMdid(state, props.mdid));
  const block = props.block;
  const buttonBackground = block.color_offer_buttons_background;
  const offerBackgroundColor = block.color_offer_background;
  const colorOfferButtonsText = block.color_offer_buttons_text;
  const textColor = block.color_offer_text

  function handleClick(mdid) {
    const options = {
      endpoint: 'offers/load',
      method: 'POST',
      body: { 
        username: username, 
        id: mdid,
        // mediaPropertyId: props.mpid,
        captchaToken: '22',
        location: 'carousel'
      },
    };

    if (!username) {
      dispatch(SHOW_LOGIN({ show: true, mdid: mdid, location: 'carousel' }));
    } else {
      handleClip(options, mdid);
    }
  }
  
  function handleClip(options, mdid) {
    dispatch(CLIP_OFFER(options)).then((resp) => {
      console.log(resp);
      if (!resp.payload.success) {
        dispatch(CLIP_ERROR(resp));
      }
    });
  }

  return (
    <div className="card" style={{ backgroundColor: offerBackgroundColor }}>
      <img className="product--image" src={props.url} alt={props.image_alt_text} />
      {/* Uncomment if needed
      <h2>{props.name}</h2>
      <p className="price">{props.price}</p>
      <p>{props.description}</p> */}
      <p>
        {stateOffer && stateOffer.clipped ? (
          <button style={{ backgroundColor: 'gray', color: colorOfferButtonsText }} disabled>
            Clipped
          </button>
        ) : (
          <button style={{ backgroundColor: buttonBackground }} onClick={() => handleClick(props.mdid)}>
            Clip Offer
          </button>
        )}
      </p>
    </div>
  );
}

import { useState, useEffect } from 'react';

function FooterV2(page) {
  const footerData = page.page.components.find(component => component.block_type === 'FooterBlock');
  const color_background = footerData.color_background;
  const color_text = footerData.color_text;

  let linksData = [
    { text: footerData.link1_text, url: footerData.link1_url, order: 1, image: footerData.link1_image },
    { text: footerData.link2_text, url: footerData.link2_url, order: 2, image: footerData.link2_image },
    { text: footerData.link3_text, url: footerData.link3_url, order: 3, image: footerData.link3_image },
    { text: footerData.link4_text, url: footerData.link4_url, order: 4, image: footerData.link4_image },
    { text: footerData.link5_text, url: footerData.link5_url, order: 5, image: footerData.link5_image },
    { text: footerData.link6_text, url: footerData.link6_url, order: 6, image: footerData.link6_image },
  ];
  
  if (footerData.enable_customize_cookie_button) {
    const customizeLink = { 
      text: footerData.customize_cookie_text || 'Customize Cookie Settings', 
      url: '#', 
      onClick: () => window.OneTrust.ToggleInfoDisplay(),
      order: footerData.customize_cookie_order || 7 
    };
  
    const index = linksData.findIndex(link => link.order >= customizeLink.order);
    if (index === -1) {
      linksData.push(customizeLink);
    } else {
      linksData.splice(index, 0, customizeLink);
    }
  }
  
  const filteredLinksData = linksData.filter(link => link.text && (link.url || link.onClick));

  if (process.env.REACT_APP_ENV !== "prod") {
    filteredLinksData.forEach(link => {
      if (process.env.REACT_APP_PREVIEW_MODE && !/^https?:\/\//i.test(link.url)) {
        link.url = link.url.startsWith('/') ? link.url : `/${link.url}`;
        link.url = `/${page.page.brandsite_name}${link.url}`;
      }
    });
  }

  const showDisclaimer = !!footerData.disclaimer_text;
  const showLinks = filteredLinksData.length > 0;
  const showContact = !!(footerData.contact_email || footerData.contact_phone);
  const showFollowUs = !!(
    footerData.facebook_link ||
    footerData.instagram_link ||
    footerData.linkedin_link ||
    footerData.twitter_link
  );
  const blockCount = [showLinks, showContact, showFollowUs].filter(value => value).length;
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <div className='tw-min-h-0'>
      {window.innerWidth > 768 ? (
        <div className="tw-w-100 tw-py-12 tw-bg-sky-700 tw-flex-col tw-justify-center tw-items-center tw-gap-8 tw-inline-flex" style={{backgroundColor:color_background, color:color_text, width: '100%'}}>
          <div className="tw-w-[83%] tw-justify-start tw-items-center tw-gap-12 tw-inline-flex">
            <div className="tw-grow tw-shrink tw-basis-0 tw-justify-start tw-items-center tw-gap-2 tw-flex">
              <div className="tw-grow tw-shrink tw-basis-0 tw-justify-between tw-items-start tw-flex">
                
                {showLinks && (
                <>
                  <div className="tw-flex-col tw-justify-start tw-items-start tw-gap-4 tw-inline-flex">
                  {filteredLinksData.slice(0, 3).map((link, index) => (
                    <a
                    key={index}
                    href={link.url}
                    className="tw-text-base tw-font-normal tw-font-['Arial'] tw-leading-tight"
                    style={{ color: footerData.color_text }}
                    onClick={link.onClick ? (e) => { e.preventDefault(); link.onClick(); } : undefined}
                  >
                      {link.text}
                      {link.image && <img src={link.image} alt={link.text} className="tw-inline-block tw-ml-2" style={{ width: '32px', height: '15px' }} />}
                    </a>
                  ))}
                  </div>
                  {filteredLinksData.length > 3 && (
                    <div className="tw-flex-col tw-justify-start tw-items-start tw-gap-4 tw-inline-flex">
                      {filteredLinksData.slice(3).map((link, index) => (
                        <a
                        key={index}
                        href={link.url}
                        className="tw-text-base tw-font-normal tw-font-['Arial'] tw-leading-tight"
                        style={{ color: footerData.color_text }}
                        onClick={link.onClick ? (e) => { e.preventDefault(); link.onClick(); } : undefined}
                        >
                        {link.text}
                        {link.image && <img src={link.image} alt={link.text} className="tw-inline-block tw-ml-2" style={{ width: '32px', height: '15px' }} />}
                        </a>
                      ))}
                    </div>
                  )}
                </>
              )}

                {showContact && (
                  <div className="tw-flex-col tw-justify-start tw-items-start tw-gap-4 tw-inline-flex">
                    <div className="tw-text-xl tw-font-bold tw-font-['Arial'] tw-leading-[25px]">Contact</div>
                    {footerData.contact_email && (
                      <div className="tw-text-base tw-font-normal tw-font-['Arial'] tw-leading-tight">
                        {footerData.contact_email}
                      </div>
                    )}
                    {footerData.contact_phone && (
                      <div className="tw-text-base tw-font-normal tw-font-['Arial'] tw-leading-tight">
                        {footerData.contact_phone}
                      </div>
                    )}
                  </div>
                )}

                {showFollowUs && (
                  <div className="tw-flex-col tw-justify-start tw-items-start tw-gap-4 tw-inline-flex">
                    <div className="tw-text-xl tw-font-bold tw-font-['Arial'] tw-leading-tight">Follow Us</div>
                    <div className="tw-justify-start tw-items-start tw-gap-4 tw-inline-flex">
                      {/* facebook */}
                      {footerData.facebook_link && (
                        <a href={footerData.facebook_link} target="_blank" rel="noopener noreferrer">
                          <svg width="13" height="24" viewBox="0 0 13 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M12.0781 13.5H8.5625V24H3.875V13.5H0.03125V9.1875H3.875V5.85938C3.875 2.10938 6.125 0 9.54688 0C11.1875 0 12.9219 0.328125 12.9219 0.328125V4.03125H11C9.125 4.03125 8.5625 5.15625 8.5625 6.375V9.1875H12.7344L12.0781 13.5Z" fill={footerData.color_icons} />
                          </svg>
                        </a>
                      )}
                      {/* insta */}
                      {footerData.instagram_link && (
                        <a href={footerData.instagram_link} target="_blank" rel="noopener noreferrer">
                          <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M11.5 5.60938C14.4531 5.60938 16.8906 8.04688 16.8906 11C16.8906 14 14.4531 16.3906 11.5 16.3906C8.5 16.3906 6.10938 14 6.10938 11C6.10938 8.04688 8.5 5.60938 11.5 5.60938ZM11.5 14.5156C13.4219 14.5156 14.9688 12.9688 14.9688 11C14.9688 9.07812 13.4219 7.53125 11.5 7.53125C9.53125 7.53125 7.98438 9.07812 7.98438 11C7.98438 12.9688 9.57812 14.5156 11.5 14.5156ZM18.3438 5.42188C18.3438 4.71875 17.7812 4.15625 17.0781 4.15625C16.375 4.15625 15.8125 4.71875 15.8125 5.42188C15.8125 6.125 16.375 6.6875 17.0781 6.6875C17.7812 6.6875 18.3438 6.125 18.3438 5.42188ZM21.9062 6.6875C22 8.42188 22 13.625 21.9062 15.3594C21.8125 17.0469 21.4375 18.5 20.2188 19.7656C19 20.9844 17.5 21.3594 15.8125 21.4531C14.0781 21.5469 8.875 21.5469 7.14062 21.4531C5.45312 21.3594 4 20.9844 2.73438 19.7656C1.51562 18.5 1.14062 17.0469 1.04688 15.3594C0.953125 13.625 0.953125 8.42188 1.04688 6.6875C1.14062 5 1.51562 3.5 2.73438 2.28125C4 1.0625 5.45312 0.6875 7.14062 0.59375C8.875 0.5 14.0781 0.5 15.8125 0.59375C17.5 0.6875 19 1.0625 20.2188 2.28125C21.4375 3.5 21.8125 5 21.9062 6.6875ZM19.6562 17.1875C20.2188 15.8281 20.0781 12.5469 20.0781 11C20.0781 9.5 20.2188 6.21875 19.6562 4.8125C19.2812 3.92188 18.5781 3.17188 17.6875 2.84375C16.2812 2.28125 13 2.42188 11.5 2.42188C9.95312 2.42188 6.67188 2.28125 5.3125 2.84375C4.375 3.21875 3.67188 3.92188 3.29688 4.8125C2.73438 6.21875 2.875 9.5 2.875 11C2.875 12.5469 2.73438 15.8281 3.29688 17.1875C3.67188 18.125 4.375 18.8281 5.3125 19.2031C6.67188 19.7656 9.95312 19.625 11.5 19.625C13 19.625 16.2812 19.7656 17.6875 19.2031C18.5781 18.8281 19.3281 18.125 19.6562 17.1875Z" fill={footerData.color_icons} />
                          </svg>
                        </a>
                      )}
                      {/* linkedin */}
                      {footerData.linkedin_link && (
                        <a href={footerData.linkedin_link} target="_blank" rel="noopener noreferrer">
                          <svg width="21" height="22" viewBox="0 0 21 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M4.6875 22H0.328125V7.98438H4.6875V22ZM2.48438 6.10938C1.125 6.10938 0 4.9375 0 3.53125C0 1.60938 2.0625 0.390625 3.75 1.375C4.54688 1.79688 5.01562 2.64062 5.01562 3.53125C5.01562 4.9375 3.89062 6.10938 2.48438 6.10938ZM20.9531 22H16.6406V15.2031C16.6406 13.5625 16.5938 11.5 14.3438 11.5C12.0938 11.5 11.7656 13.2344 11.7656 15.0625V22H7.40625V7.98438H11.5781V9.90625H11.625C12.2344 8.82812 13.6406 7.65625 15.75 7.65625C20.1562 7.65625 21 10.5625 21 14.3125V22H20.9531Z" fill={footerData.color_icons} />
                          </svg>
                        </a>
                      )}
                      {/* twitter */}
                      {footerData.twitter_link && (
                        <a href={footerData.twitter_link} target="_blank" rel="noopener noreferrer">
                          <svg width="24" height="20" viewBox="0 0 24 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M21.5156 5.125C21.5156 5.35938 21.5156 5.54688 21.5156 5.78125C21.5156 12.2969 16.5938 19.75 7.54688 19.75C4.73438 19.75 2.15625 18.9531 0 17.5469C0.375 17.5938 0.75 17.6406 1.17188 17.6406C3.46875 17.6406 5.57812 16.8438 7.26562 15.5312C5.10938 15.4844 3.28125 14.0781 2.67188 12.1094C3 12.1562 3.28125 12.2031 3.60938 12.2031C4.03125 12.2031 4.5 12.1094 4.875 12.0156C2.625 11.5469 0.9375 9.57812 0.9375 7.1875V7.14062C1.59375 7.51562 2.39062 7.70312 3.1875 7.75C1.82812 6.85938 0.984375 5.35938 0.984375 3.67188C0.984375 2.73438 1.21875 1.89062 1.64062 1.1875C4.07812 4.14062 7.73438 6.10938 11.8125 6.34375C11.7188 5.96875 11.6719 5.59375 11.6719 5.21875C11.6719 2.5 13.875 0.296875 16.5938 0.296875C18 0.296875 19.2656 0.859375 20.2031 1.84375C21.2812 1.60938 22.3594 1.1875 23.2969 0.625C22.9219 1.79688 22.1719 2.73438 21.1406 3.34375C22.125 3.25 23.1094 2.96875 23.9531 2.59375C23.2969 3.57812 22.4531 4.42188 21.5156 5.125Z" fill={footerData.color_icons} />
                          </svg>
                        </a>
                      )}
                    </div>
                  </div>
                )}

              </div>
            </div>
          </div>
          {showDisclaimer && (
            <>
              {blockCount !== 0 && (
                <div className="tw-w-[83%] tw-h-px tw-rounded-[150px] tw-justify-start tw-items-center tw-inline-flex">
                  <div className="tw-grow tw-shrink tw-basis-0 tw-self-stretch tw-bg-white" />
                </div>
              )}
              <div className="tw-w-[83%] tw-justify-start tw-items-start tw-gap-[120px] tw-inline-flex">
                <div className="tw-grow tw-shrink tw-basis-0 tw-text-[13px] tw-font-normal tw-font-['Arial'] tw-leading-none tw-text-left">
                  {footerData.disclaimer_text}
                </div>
              </div>
            </>
          )}
        </div>
      ) : (
        <div className="tw-w-100 tw-py-8 tw-flex-col tw-gap-8 tw-inline-flex" style={{ backgroundColor: footerData.color_background, color: footerData.color_text, width: '100%' }}>
          <div className={`tw-self-stretch tw-px-4 ${showLinks && blockCount === 1 ? 'tw-justify-start' : 'tw-justify-center'} tw-items-center tw-inline-flex`}>
            <div className="tw-gap-4 tw-flex">
            {showLinks && (
              <div className="tw-w-[179px] tw-flex-col tw-items-start tw-gap-4 tw-inline-flex">
                {filteredLinksData.map((link, index) => (
                  <a
                    key={index}
                    href={link.url}
                    className="tw-text-[13px] tw-font-normal tw-font-['Arial'] tw-leading-none"
                    style={{ color: footerData.color_text }}
                    onClick={link.onClick ? (e) => { e.preventDefault(); link.onClick(); } : undefined}
                  >
                    {link.text}
                    {link.image && <img src={link.image} alt={link.text} className="tw-inline-block tw-ml-2" style={{ width: '32px', height: '15px' }} />}
                  </a>
                ))}
              </div>
            )}
              <div className={`${blockCount > 1 && !showLinks ? 'tw-flex tw-gap-16' : 'tw-flex-col'} tw-justify-between ${showLinks ? 'tw-inline-flex' : ''}`}>
                {showContact && (
                  <div className={`tw-h-[84px] tw-flex-col ${blockCount === 1 ? 'tw-items-center' : 'tw-items-start'} tw-gap-4 tw-flex`}>
                    <div className="tw-text-base tw-font-bold tw-font-['Arial'] tw-leading-tight">Contact</div>
                    {footerData.contact_email && (
                      <div className="tw-text-[13px] tw-font-normal tw-font-['Arial'] tw-leading-none">
                        {footerData.contact_email}
                      </div>
                    )}
                    {footerData.contact_phone && (
                      <div className="tw-text-[13px] tw-font-normal tw-font-['Arial'] tw-leading-none">
                        {footerData.contact_phone}
                      </div>
                    )}
                  </div>
                )}
                {showFollowUs && (
                  <div className={`tw-h-[60px] tw-flex-col tw-justify-center ${blockCount === 1 ? 'tw-items-center' : 'tw-items-start'} tw-gap-4 tw-flex`}>
                    <div className="tw-text-base tw-font-bold tw-font-['Arial'] tw-leading-tight">Follow Us</div>
                    <div className="tw-gap-4 tw-inline-flex">
                      {/* facebook */}
                      {footerData.facebook_link && (
                        <a href={footerData.facebook_link} target="_blank" rel="noopener noreferrer">
                          <svg width="13" height="24" viewBox="0 0 13 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M12.0781 13.5H8.5625V24H3.875V13.5H0.03125V9.1875H3.875V5.85938C3.875 2.10938 6.125 0 9.54688 0C11.1875 0 12.9219 0.328125 12.9219 0.328125V4.03125H11C9.125 4.03125 8.5625 5.15625 8.5625 6.375V9.1875H12.7344L12.0781 13.5Z" fill={footerData.color_icons} />
                          </svg>
                        </a>
                      )}
                      {/* insta */}
                      {footerData.instagram_link && (
                        <a href={footerData.instagram_link} target="_blank" rel="noopener noreferrer">
                          <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M11.5 5.60938C14.4531 5.60938 16.8906 8.04688 16.8906 11C16.8906 14 14.4531 16.3906 11.5 16.3906C8.5 16.3906 6.10938 14 6.10938 11C6.10938 8.04688 8.5 5.60938 11.5 5.60938ZM11.5 14.5156C13.4219 14.5156 14.9688 12.9688 14.9688 11C14.9688 9.07812 13.4219 7.53125 11.5 7.53125C9.53125 7.53125 7.98438 9.07812 7.98438 11C7.98438 12.9688 9.57812 14.5156 11.5 14.5156ZM18.3438 5.42188C18.3438 4.71875 17.7812 4.15625 17.0781 4.15625C16.375 4.15625 15.8125 4.71875 15.8125 5.42188C15.8125 6.125 16.375 6.6875 17.0781 6.6875C17.7812 6.6875 18.3438 6.125 18.3438 5.42188ZM21.9062 6.6875C22 8.42188 22 13.625 21.9062 15.3594C21.8125 17.0469 21.4375 18.5 20.2188 19.7656C19 20.9844 17.5 21.3594 15.8125 21.4531C14.0781 21.5469 8.875 21.5469 7.14062 21.4531C5.45312 21.3594 4 20.9844 2.73438 19.7656C1.51562 18.5 1.14062 17.0469 1.04688 15.3594C0.953125 13.625 0.953125 8.42188 1.04688 6.6875C1.14062 5 1.51562 3.5 2.73438 2.28125C4 1.0625 5.45312 0.6875 7.14062 0.59375C8.875 0.5 14.0781 0.5 15.8125 0.59375C17.5 0.6875 19 1.0625 20.2188 2.28125C21.4375 3.5 21.8125 5 21.9062 6.6875ZM19.6562 17.1875C20.2188 15.8281 20.0781 12.5469 20.0781 11C20.0781 9.5 20.2188 6.21875 19.6562 4.8125C19.2812 3.92188 18.5781 3.17188 17.6875 2.84375C16.2812 2.28125 13 2.42188 11.5 2.42188C9.95312 2.42188 6.67188 2.28125 5.3125 2.84375C4.375 3.21875 3.67188 3.92188 3.29688 4.8125C2.73438 6.21875 2.875 9.5 2.875 11C2.875 12.5469 2.73438 15.8281 3.29688 17.1875C3.67188 18.125 4.375 18.8281 5.3125 19.2031C6.67188 19.7656 9.95312 19.625 11.5 19.625C13 19.625 16.2812 19.7656 17.6875 19.2031C18.5781 18.8281 19.3281 18.125 19.6562 17.1875Z" fill={footerData.color_icons} />
                            </svg>
                        </a>
                      )}
                      {/* linkedin */}
                      {footerData.linkedin_link && (
                        <a href={footerData.linkedin_link} target="_blank" rel="noopener noreferrer">
                          <svg width="21" height="22" viewBox="0 0 21 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M4.6875 22H0.328125V7.98438H4.6875V22ZM2.48438 6.10938C1.125 6.10938 0 4.9375 0 3.53125C0 1.60938 2.0625 0.390625 3.75 1.375C4.54688 1.79688 5.01562 2.64062 5.01562 3.53125C5.01562 4.9375 3.89062 6.10938 2.48438 6.10938ZM20.9531 22H16.6406V15.2031C16.6406 13.5625 16.5938 11.5 14.3438 11.5C12.0938 11.5 11.7656 13.2344 11.7656 15.0625V22H7.40625V7.98438H11.5781V9.90625H11.625C12.2344 8.82812 13.6406 7.65625 15.75 7.65625C20.1562 7.65625 21 10.5625 21 14.3125V22H20.9531Z" fill={footerData.color_icons} />
                          </svg>
                        </a>
                      )}
                      {/* twitter */}
                      {footerData.twitter_link && (
                        <a href={footerData.twitter_link} target="_blank" rel="noopener noreferrer">
                          <svg width="24" height="20" viewBox="0 0 24 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M21.5156 5.125C21.5156 5.35938 21.5156 5.54688 21.5156 5.78125C21.5156 12.2969 16.5938 19.75 7.54688 19.75C4.73438 19.75 2.15625 18.9531 0 17.5469C0.375 17.5938 0.75 17.6406 1.17188 17.6406C3.46875 17.6406 5.57812 16.8438 7.26562 15.5312C5.10938 15.4844 3.28125 14.0781 2.67188 12.1094C3 12.1562 3.28125 12.2031 3.60938 12.2031C4.03125 12.2031 4.5 12.1094 4.875 12.0156C2.625 11.5469 0.9375 9.57812 0.9375 7.1875V7.14062C1.59375 7.51562 2.39062 7.70312 3.1875 7.75C1.82812 6.85938 0.984375 5.35938 0.984375 3.67188C0.984375 2.73438 1.21875 1.89062 1.64062 1.1875C4.07812 4.14062 7.73438 6.10938 11.8125 6.34375C11.7188 5.96875 11.6719 5.59375 11.6719 5.21875C11.6719 2.5 13.875 0.296875 16.5938 0.296875C18 0.296875 19.2656 0.859375 20.2031 1.84375C21.2812 1.60938 22.3594 1.1875 23.2969 0.625C22.9219 1.79688 22.1719 2.73438 21.1406 3.34375C22.125 3.25 23.1094 2.96875 23.9531 2.59375C23.2969 3.57812 22.4531 4.42188 21.5156 5.125Z" fill={footerData.color_icons} />
                            </svg>
                        </a>
                      )}
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
          {showDisclaimer && (
            <>
              {blockCount !== 0 && (
                <div className="tw-h-px tw-rounded-[150px] tw-inline-flex">
                  <div className="tw-grow tw-shrink tw-basis-0 tw-self-stretch tw-bg-white" />
                </div>
              )}
              <div className="tw-self-stretch tw-px-4 tw-justify-start tw-items-start tw-gap-[120px] tw-inline-flex">
                <div className="tw-text-[13px] tw-font-normal tw-font-['Arial'] tw-leading-none tw-text-left">
                  {footerData.disclaimer_text}
                </div>
              </div>
            </>
          )}
        </div>
      )}
    </div>
  );
}

export default FooterV2;
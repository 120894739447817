import React, { useEffect, useState } from 'react'
import { Grid } from '@material-ui/core';
import ProgramPod from './ProgramPod'
import { getAvailableProgramDetials } from '../service/myloyalty_services'
import { makeStyles } from '@material-ui/core/styles';

function EnrolledPrograms({props}) {
  const useStyles = makeStyles((theme) => ({
    itemContainer:{
     padding:'80px 24px',
     justifyContent:'center',
     [theme.breakpoints.down("xs")]: {
         padding:'48px 16px',
         justifyContent:'flex-start'
       },
    },
    gridItem:{
      margin:'8px 8px',
      alignItems: 'center',
    }
   }));
   const classes=useStyles();
  const [enrolledPrograms, setEnrolledPrograms] = useState([])
    useEffect(()=>{
      const token = localStorage.getItem('token');
      const retailer = props.retailer.loyalty_name
      const getPrograms = getAvailableProgramDetials(token, retailer, 'clipped, redeemed');
      getPrograms
      .then(
          (res)=>
              {
                  setEnrolledPrograms(res.items)
                }
        )
        .catch((err)=>console.log(err))
    },[])

  return (
    <div>
        <Grid
            container
            direction="row"
            alignItems="stretch"
            className={classes.itemContainer}
          >
            { enrolledPrograms && enrolledPrograms.length >0 && enrolledPrograms.map((item, i) => (
             <Grid item key={i} className={classes.gridItem}>
                {' '}
                <ProgramPod programs={item} props={props} value="clipped"></ProgramPod>
              </Grid>
            ))}
          </Grid>
    </div>
  )
}

export default EnrolledPrograms